h1 i {
    color: #ccc;
    margin-left: 10px;
    transition: color 0.25s;
}

h1 i:hover {
    color: #888;
}

h1 i.fa-spin {
    color: #eee;
    cursor: text;
}

h1 small {
    display: block;
    font-size: large;
    margin: 5px 0 20px;
}

.list-group-item {
    cursor: default;
}
